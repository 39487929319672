import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { sendAzureEvent } from 'src/helpers/appInsights';

import {
    contributingEventMetaDataActionCreator,
    dashboardMetaDataActionCreator,
    insightIdActionCreator,
} from '../store/app/app.slice';
import { IInsightFeedback } from '../types';
import { setValuesFromStateInLocalStorage } from '../helpers/SessionHelper';
import { RootState } from '../store/store';
import { setAnchorEvent } from '../store/ui/ui.slice';
import { appInitialState } from '../store/app/app.initialState';
import { sendFeedback } from '../store/app/app.thunks';
import InsightEdNotes from '../components/insight/InsightEdNotes';
import InsightUserFeedback from '../components/insight/InsightUserFeedback';
import InsightEdWarning from '../components/insight/InsightEdWarning';
import InsightHelper from '../helpers/InsightHelper';
import SystemHelper from '../helpers/SystemHelper';
import InsightDetail from '../components/insight/InsightDetail';
import styleGeneral from '../styles/general.module.scss';
import BtnBack from '../components/common/btnBack';
import ConstantsHelper from '../helpers/ConstantsHelper';
import NavigationHelper from '../helpers/NavigationHelper';
import UiHelper from '../helpers/UiHelper';
import ContentWrapperAll from '../components/common/contentWrappers/ContentWrapperAll';

function InsightDetails(props: any) {
    const dispatch = useDispatch();

    const anchorEvent = useSelector((state: RootState) => state.ui.anchorEvent);

    const bodyRef = useRef<HTMLDivElement>();
    const app = SystemHelper.GetCleanState(
        useSelector((state: RootState) => state.app),
        appInitialState
    );
    const translate = (key: string, subs?: any) => UiHelper.Translate(app, key, subs);
    const patient = app.patient;
    const { dashboardBeg, dashboardEnd, dashboardHasReferrer, insightId } = props?.match?.params ?? {};

    const handleScroll = (event: any) =>
        NavigationHelper.HandleScroll(
            undefined,
            scrollActive1,
            scrollActive2,
            setScrollActive1,
            setScrollActive2,
            event?.target?.scrollTop
        );

    const armEventReadings = ({
        anchorEvent,
        contributingEventId,
        contributingEventBeg,
        contributingEventEnd,
    }: any) => {
        dispatch(
            contributingEventMetaDataActionCreator({
                contributingEventId,
                contributingEventBeg,
                contributingEventEnd,
            })
        );

        setValuesFromStateInLocalStorage({
            [ConstantsHelper.IntersessionKeys.contributingEventId]: app.contributingEventId,
            [ConstantsHelper.IntersessionKeys.contributingEventBeg]: app.contributingEventBeg,
            [ConstantsHelper.IntersessionKeys.contributingEventEnd]: app.contributingEventEnd,
        });

        dispatch(setAnchorEvent({ anchorEvent }));
    };

    const setFeedback = (payload: IInsightFeedback) => dispatch(sendFeedback(payload));

    useEffect(() => {
        /* istanbul ignore next: no else condition */
        if (
            !(
                app.dashboardBeg === dashboardBeg &&
                app.dashboardEnd === dashboardEnd &&
                app.dashboardHasReferrer === (dashboardHasReferrer === 'true')
            )
        ) {
            dispatch(
                dashboardMetaDataActionCreator({
                    dashboardBeg,
                    dashboardEnd,
                    dashboardHasReferrer: dashboardHasReferrer === 'true',
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardBeg, dashboardEnd, dashboardHasReferrer]);

    useEffect(() => {
        if (app.insightId !== insightId) {
            dispatch(insightIdActionCreator({ insightId }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [insightId]);

    const dashboard = app.currentDashboard;
    const insight = app.cachedInsights[app.insightId];

    const clearAnchorEvent = () => dispatch(setAnchorEvent({ anchorEvent: undefined }));

    const [scrollActive1, setScrollActive1] = useState(false);
    const [scrollActive2, setScrollActive2] = useState(false);

    useLayoutEffect(() => {
        if (insight?.insightId && insight?.insightType && insight?.patternType) {
            SystemHelper.AppInsightsEvent(app, 'InsightDetails()', 'Mounted');
            sendAzureEvent(ConstantsHelper.analyticsEventNames.VIEW_INSIGHT_DETAILS, {
                insightId: insight?.insightId,
                insightType: insight?.insightType,
                patternType: insight?.patternType,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (patient.receivedData && !(app.dashboardEnd && app.insightId && dashboard?.insightIds)) {
            const payload = UiHelper.GetInsightsDateRange(dispatch, app);

            if (payload.beg && payload.end) {
                UiHelper.ClearErrors(dispatch);
                UiHelper.FetchDashboard(dispatch, payload.beg, payload.end, app);
                UiHelper.FetchDashboardInsulinUsage(dispatch, payload.beg, payload.end, app);
                UiHelper.FetchDashboardInsulinUsageTrend(dispatch, payload.beg, payload.end, app);
                UiHelper.FetchDashboardSummary(dispatch, payload.beg, payload.end, app);
                UiHelper.FetchDashboardSummaryGlucoseTrend(dispatch, payload.beg, payload.end, app);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patient.receivedData, app.patient?.confidentialityAgreementAccepted, app.patient?.eulaAccepted]);

    useEffect(() => {
        if (anchorEvent && insight) {
            NavigationHelper.ScrollIntoView(anchorEvent, clearAnchorEvent);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [anchorEvent, insight]);

    const insightAttributes = InsightHelper.GetInsightAttributes(app, insight, translate);

    return (
        <ContentWrapperAll app={app} dispatch={dispatch} showIfInvalidEnrollment={false}>
            {dashboard?.insightIds && (
                <div className={styleGeneral.body} onScroll={(event) => handleScroll(event)} ref={bodyRef}>
                    <div className={styleGeneral.stickToTopBack}>
                        <BtnBack
                            url={NavigationHelper.GenUrlDashboard(
                                app.dashboardBeg,
                                app.dashboardEnd,
                                app.dashboardHasReferrer
                            )}
                        />
                    </div>

                    <div className={styleGeneral.insightEntryDetail}>
                        <InsightDetail
                            cbClickEventFn={armEventReadings}
                            app={app}
                            translate={translate}
                            insight={insight}
                            insightAttributes={insightAttributes}
                            parentScrollNode={bodyRef.current}
                            scrollActive1={scrollActive1}
                        />
                    </div>

                    <div className={styleGeneral.insightEntryFooter}>
                        <InsightEdNotes notes={insightAttributes.edNotes} translate={translate} />

                        <InsightUserFeedback
                            app={app}
                            cbClickFeedbackFn={(params: IInsightFeedback) => setFeedback(params)}
                            insight={insight}
                            translate={translate}
                        />

                        <InsightEdWarning notes={insightAttributes.edNotes} translate={translate} />
                    </div>
                </div>
            )}
        </ContentWrapperAll>
    );
}

export default InsightDetails;
