import { useTranslation } from 'react-i18next';
import React from 'react';
import clsx from 'clsx';

import styleGeneral from '../../../styles/general.module.scss';
import styleGuide from '../../../styles/styleGuide.module.scss';
import { ICallback } from '../../../types';
import BtnPrimary from '../btnPrimary';

function ContentWrapperEnrollmentPending({ podderCentralCb }: { podderCentralCb: ICallback<void> }) {
    const { t } = useTranslation();

    const podderCentralSlug = (
        <>
            {t('enrollmentPending.podderCentral')}
            <span className={styleGuide.labelAlt}>®</span>
        </>
    );

    return (
        <div className={styleGuide.body}>
            <div
                className={clsx(
                    styleGeneral.errorPage,
                    styleGeneral.errorPageTest,
                    styleGeneral.layerTopmostLess1,
                    styleGuide.enrollmentPending
                )}
            >
                <div className={styleGuide.title}>{t('enrollmentPending.title')}</div>
                <div className={styleGuide.body}>
                    {t('enrollmentPending.subTitlePre')}&nbsp; {podderCentralSlug}&nbsp;
                    {t('enrollmentPending.subTitlePost')}
                </div>
                <div>
                    <BtnPrimary caption={podderCentralSlug} cbClickFn={podderCentralCb} />
                </div>
            </div>
        </div>
    );
}

export default ContentWrapperEnrollmentPending;
