import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import ar from './locales/ar.json';
import de from './locales/de.json';
import en from './locales/en.json';
import fi from './locales/fi.json';
import he from './locales/he.json';
import ja from './locales/ja.json';
import pt from './locales/pt.json';
import zh from './locales/zh.json';
import es from './locales/es.json';

i18n.use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources: {
            ar: {
                translation: ar,
            },
            de: {
                translation: de,
            },
            en: {
                translation: en,
            },
            es: {
                translation: es,
            },
            fi: {
                translation: fi,
            },
            he: {
                translation: he,
            },
            ja: {
                translation: ja,
            },
            pt: {
                translation: pt,
            },
            zh: {
                translation: zh,
            },
        },
        fallbackLng: 'en',
        debug: true,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

export default i18n;
