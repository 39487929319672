import { useDispatch, useSelector } from 'react-redux';
import React from 'react';

import { RootState } from '../store/store';
import { appInitialState } from '../store/app/app.initialState';
import SystemHelper from '../helpers/SystemHelper';
import ContentWrapperAll from '../components/common/contentWrappers/ContentWrapperAll';

function Loading() {
    const dispatch = useDispatch();
    const app = SystemHelper.GetCleanState(
        useSelector((state: RootState) => state.app),
        appInitialState
    );

    return <ContentWrapperAll app={app} dispatch={dispatch} showIfInvalidEnrollment={false} />;
}

export default Loading;
