import { createSlice } from '@reduxjs/toolkit';
import { parse } from 'node-html-parser';

import {
    acceptClinicInvitation,
    denyClinicInvitation,
    fetchBackEndId,
    fetchClinics,
    fetchDashboard,
    fetchDashboardInsulinUsage,
    fetchDashboardInsulinUsageTrend,
    fetchDashboardSummary,
    fetchDashboardSummaryGlucoseTrend,
    fetchHcpReport,
    fetchInsightEventBoluses,
    fetchInsightEventModes,
    fetchInsightEventReadings,
    fetchLearningMaterialOp5Progress,
    fetchLearningMaterialResources,
    fetchProfile,
    sendFeedback,
    updateProfile,
} from './app.thunks';
import { IInsulinUsage } from './app.types';
import { appInitialState } from './app.initialState';
import appReducers from './app.reducers';
import restoreStateFromLocalStorageFulfilled from './extraReducers/restoreStateFromLocalStorage/fulfilled.extraReducer';

import {
    LearningMaterialProgress,
    LearningMaterialResource,
    ReleaseNotesJSONDataItem,
    Clinic,
} from '../../model/models';
import { resetStore, restoreStateFromLocalStorage } from '../root.actions';
import AnI18NextLibHelper from '../../helpers/AnI18NextLibHelper';
import ConstantsHelper from '../../helpers/ConstantsHelper';
import DateTimeHelper from '../../helpers/DateTimeHelper';
import InsightHelper from '../../helpers/InsightHelper';
import EventHelper from '../../helpers/EventHelper';
import StringHelper from '../../helpers/StringHelper';
import UiHelper from '../../helpers/UiHelper';
import UtilityHelper from '../../helpers/UtilityHelper';

const appSlice = createSlice({
    name: 'app',
    initialState: appInitialState,
    reducers: appReducers,
    extraReducers: (builder) => {
        builder
            .addCase(restoreStateFromLocalStorage, restoreStateFromLocalStorageFulfilled)
            .addCase(resetStore, () => appInitialState)
            .addCase(sendFeedback.pending, (state, action) => {
                state.loadingSemaphore++;

                const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.sendFeedback, [
                    action.meta?.arg?.insightId,
                    action.meta?.arg?.feedbackValue,
                    action.meta?.arg?.insightIdParent,
                ]);

                state.activeHttpCalls[callKey] = true;

                const insight = state.cachedInsights[action.meta.arg.insightId];

                if (insight) {
                    insight.feedbackValue = action.meta.arg.feedbackValue;
                }

                const message = AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.sendingFeedback');

                state.message = message;
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.info,
                    message,
                    sticky: false,
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.feedback'),
                });
            })
            .addCase(sendFeedback.fulfilled, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.sendFeedback, [
                    action.meta?.arg?.insightId,
                    action.meta?.arg?.feedbackValue,
                    action.meta?.arg?.insightIdParent,
                ]);

                if (callKey) {
                    delete state.activeHttpCalls[callKey];
                }

                if (action.payload) {
                    [action.payload.insightId, action.payload.insightIdParent].forEach((insightId) => {
                        if (insightId) {
                            const insight = state.cachedInsights[insightId];

                            if (insight) {
                                insight.feedbackValue = action.payload.feedbackValue;
                            }
                        }
                    });
                }

                const message = AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.thankYouFeedback');

                state.message = message;
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.success,
                    message,
                    showAlways: false,
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.feedback'),
                });
                state.loadingSemaphore--;
            })
            .addCase(sendFeedback.rejected, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.sendFeedback, [
                    action.meta?.arg?.insightId,
                    action.meta?.arg?.feedbackValue,
                    action.meta?.arg?.insightIdParent,
                ]);
                const message =
                    action?.payload?.message ??
                    action?.error?.message ??
                    AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.Error');

                state.message = message;
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.error,
                    message,
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.feedback'),
                });
                state.loadingSemaphore--;
            })
            .addCase(fetchBackEndId.pending, (state) => {
                state.loadingSemaphore++;

                const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchBackEndId);

                state.activeHttpCalls[callKey] = true;
                state.versionBackEnd = undefined;
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.info,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pending'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.backEndId'),
                });
            })
            .addCase(fetchBackEndId.fulfilled, (state, { payload }) => {
                const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchBackEndId);

                if (callKey) {
                    delete state.activeHttpCalls[callKey];
                }

                const dataRecs = parse(payload, {
                    lowerCaseTagName: true,
                    comment: false,
                    blockTextElements: {
                        script: false,
                        style: false,
                    },
                })
                    ?.getElementsByTagName('tr')
                    ?.map((trEntry) => {
                        const labels = trEntry.getElementsByTagName('th');
                        const values = trEntry.getElementsByTagName('td');

                        return labels?.length > 0 && values?.length > 0
                            ? `${labels[0].innerText.toLowerCase()}=${values[0].innerText}`
                            : '';
                    })
                    ?.filter((line) => line?.trim()?.length > 0);
                const data: ReleaseNotesJSONDataItem = {
                    cICDBuildPipelineID: `${dataRecs.find(
                        (line) => line.indexOf('ci/cd build pipeline id=') > -1
                    )}=`.split('=')[1],
                    cICDReleasePipelineID: `${dataRecs.find(
                        (line) => line.indexOf('ci/cd release pipeline id=') > -1
                    )}=`.split('=')[1],
                    notes: [],
                    repositoryBranch: undefined,
                    storageAccess: 'ok',
                    version: `${dataRecs.find((line) => line.indexOf('version=') > -1)}=`.split('=')[1],
                };
                state.versionBackEnd = [
                    data.version.length > 0 ? data.version : '--',
                    data.cICDBuildPipelineID,
                    data.cICDReleasePipelineID,
                ]
                    .filter((entry) => entry.length > 0)
                    .join(' / ');
                state.errors[callKey] = {
                    level: ConstantsHelper.ErrorLevels.success,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.success'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.backEndId'),
                };
                state.loadingSemaphore--;
            })
            .addCase(fetchBackEndId.rejected, (state) => {
                const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchBackEndId);

                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.error,
                    message: `${AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.invalidResponse')}`,
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.backEndId'),
                });
                state.loadingSemaphore--;
            })
            .addCase(updateProfile.pending, (state, action) => {
                state.loadingSemaphore++;

                const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.updateProfile, [
                    `${action.meta?.arg?.disclaimerSeen}`,
                    `${action.meta?.arg?.welcomeSeen}`,
                    `${action.meta?.arg?.eulaAccepted}`,
                    `${action.meta?.arg?.confidentialityAgreementAccepted}`,
                ]);

                state.activeHttpCalls[callKey] = true;
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.info,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pending'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pwdProfilePut'),
                });
            })
            .addCase(updateProfile.fulfilled, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.updateProfile, [
                    `${action.meta?.arg?.disclaimerSeen}`,
                    `${action.meta?.arg?.welcomeSeen}`,
                    `${action.meta?.arg?.eulaAccepted}`,
                    `${action.meta?.arg?.confidentialityAgreementAccepted}`,
                ]);

                if (callKey) {
                    delete state.activeHttpCalls[callKey];
                }

                state.patient = {
                    ...state.patient,
                    ...action.payload,
                };
                state.errors[callKey] = action.payload
                    ? {
                          level: ConstantsHelper.ErrorLevels.success,
                          message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.success'),
                          title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pwdProfilePut'),
                      }
                    : {
                          level: ConstantsHelper.ErrorLevels.error,
                          message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pwdProfileNotFound'),
                          title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pwdProfilePut'),
                      };

                state.loadingSemaphore--;
            })
            .addCase(updateProfile.rejected, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.updateProfile, [
                    `${action.meta?.arg?.disclaimerSeen}`,
                    `${action.meta?.arg?.welcomeSeen}`,
                    `${action.meta?.arg?.eulaAccepted}`,
                    `${action.meta?.arg?.confidentialityAgreementAccepted}`,
                ]);

                state.patient.disclaimerSeen = false;
                state.patient.welcomeSeen = false;
                state.patient.eulaAccepted = false;
                state.patient.confidentialityAgreementAccepted = false;
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.error,
                    message: `${AnI18NextLibHelper.Translate(
                        state.anI18Nextlib,
                        'errors.pwdProfilePutFailed'
                    )}.\n${AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.reason')}:  ${
                        action?.payload?.message
                    }`,
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pwdProfilePut'),
                });

                state.loadingSemaphore--;
            })
            .addCase(fetchProfile.pending, (state) => {
                state.loadingSemaphore++;

                const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchProfile);

                state.activeHttpCalls[callKey] = true;
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.info,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pending'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pwdProfileGet'),
                });
            })
            .addCase(fetchProfile.fulfilled, (state, { payload }) => {
                const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchProfile);

                if (callKey) {
                    delete state.activeHttpCalls[callKey];
                }

                state.patient = {
                    ...payload,
                    firstJoined: UtilityHelper.CryptoEncrypt(payload.firstJoined),
                    firstName: UtilityHelper.CryptoEncrypt(payload.firstName),
                    id: UtilityHelper.CryptoEncrypt(payload.id),
                    lastName: UtilityHelper.CryptoEncrypt(payload.lastName),
                    reportDaysAvailableString: UtilityHelper.CryptoEncrypt(`${payload.reportDaysAvailable}`),
                    reportDaysAvailable: -999,
                };
                state.errors[callKey] = payload.id
                    ? {
                          level: ConstantsHelper.ErrorLevels.success,
                          message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.success'),
                          title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pwdProfileGet'),
                      }
                    : {
                          level: ConstantsHelper.ErrorLevels.info,
                          message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pwdProfileNotFound'),
                          title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pwdProfileGet'),
                      };
                state.loadingSemaphore--;
            })
            .addCase(fetchProfile.rejected, (state, { payload }) => {
                const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchProfile);

                state.patient.disclaimerSeen = false;
                state.patient.welcomeSeen = false;
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.error,
                    message:
                        payload?.message ??
                        `${AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.invalidResponse')}`,
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pwdProfileGet'),
                });
                state.loadingSemaphore--;
            })
            .addCase(fetchLearningMaterialResources.pending, (state, action) => {
                state.loadingSemaphore++;

                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchLearningMaterialResources,
                    [action.meta?.arg?.beg, action.meta?.arg?.end]
                );

                state.activeHttpCalls[callKey] = true;
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.info,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pending'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.failedLearningMaterialResource'),
                });
            })
            .addCase(fetchLearningMaterialResources.fulfilled, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchLearningMaterialResources,
                    [action.meta?.arg?.beg, action.meta?.arg?.end]
                );

                if (callKey) {
                    delete state.activeHttpCalls[callKey];
                }

                if (action.payload) {
                    const currentLearningMaterialResource: LearningMaterialResource = {
                        ...action.payload.data.item?.resources,
                    };

                    state.currentLearningMaterialResource = {
                        id: state.dashboardEnd,
                        data: currentLearningMaterialResource,
                        expires: DateTimeHelper.AddSecs(DateTimeHelper.GetIsoNow(), 60),
                    };
                    state.errors[callKey] = UiHelper.CreateErrorMessage({
                        level: ConstantsHelper.ErrorLevels.success,
                        message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.success'),
                        title: AnI18NextLibHelper.Translate(
                            state.anI18Nextlib,
                            'errors.failedLearningMaterialResource'
                        ),
                    });
                }

                state.loadingSemaphore--;
            })
            .addCase(fetchLearningMaterialResources.rejected, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchLearningMaterialResources,
                    [action.meta?.arg?.beg, action.meta?.arg?.end]
                );

                if (callKey) {
                    delete state.activeHttpCalls[callKey];
                }
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.error,
                    message:
                        action?.payload?.message ??
                        action?.error?.message ??
                        AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.Error'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.failedLearningMaterialResource'),
                });
                state.loadingSemaphore--;
            })
            .addCase(fetchLearningMaterialOp5Progress.pending, (state, action) => {
                state.loadingSemaphore++;

                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchLearningMaterialOp5Progress,
                    [action.meta?.arg?.beg, action.meta?.arg?.end]
                );

                state.activeHttpCalls[callKey] = true;
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.info,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pending'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.failedLearningMaterialProgress'),
                });
            })
            .addCase(fetchLearningMaterialOp5Progress.fulfilled, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchLearningMaterialOp5Progress,
                    [action.meta?.arg?.beg, action.meta?.arg?.end]
                );

                if (callKey) {
                    delete state.activeHttpCalls[callKey];
                }

                if (action.payload) {
                    const currentLearningMaterialProgress: LearningMaterialProgress = {
                        ...action.payload.data.item.progress,
                    };

                    state.currentLearningMaterialProgress = {
                        id: state.dashboardEnd,
                        data: currentLearningMaterialProgress,
                        expires: DateTimeHelper.AddSecs(DateTimeHelper.GetIsoNow(), 60),
                    };

                    state.patient.isOnboarded = (currentLearningMaterialProgress.weekNumber ?? -1) === -1;

                    state.errors[callKey] = UiHelper.CreateErrorMessage({
                        level: ConstantsHelper.ErrorLevels.success,
                        message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.success'),
                        title: AnI18NextLibHelper.Translate(
                            state.anI18Nextlib,
                            'errors.failedLearningMaterialProgress'
                        ),
                    });
                }

                state.loadingSemaphore--;
            })
            .addCase(fetchLearningMaterialOp5Progress.rejected, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchLearningMaterialOp5Progress,
                    [action.meta?.arg?.beg, action.meta?.arg?.end]
                );

                if (callKey) {
                    delete state.activeHttpCalls[callKey];
                }

                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.error,
                    message:
                        action?.payload?.message ??
                        action?.error?.message ??
                        AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.Error'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.failedLearningMaterialProgress'),
                });

                state.loadingSemaphore--;
            })
            .addCase(fetchDashboardInsulinUsage.pending, (state, action) => {
                state.loadingSemaphore++;

                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchDashboardInsulinUsage,
                    [action.meta?.arg?.beg, action.meta?.arg?.end]
                );

                state.activeHttpCalls[callKey] = true;
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.info,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pending'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.dashboardInsulinUsage'),
                });
            })
            .addCase(fetchDashboardInsulinUsage.fulfilled, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchDashboardInsulinUsage,
                    [action.meta?.arg?.beg, action.meta?.arg?.end]
                );

                if (callKey) {
                    delete state.activeHttpCalls[callKey];
                }

                if (action.payload) {
                    const currentInsulinUsage: IInsulinUsage = {
                        ...state.currentInsulinUsage,
                        ...action.payload.data.item,
                    };

                    state.currentInsulinUsage = currentInsulinUsage;
                    state.errors[callKey] = UiHelper.CreateErrorMessage({
                        level: ConstantsHelper.ErrorLevels.success,
                        message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.success'),
                        title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.dashboardInsulinUsage'),
                    });
                }

                state.loadingSemaphore--;
            })
            .addCase(fetchDashboardInsulinUsage.rejected, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchDashboardInsulinUsage,
                    [action.meta?.arg?.beg, action.meta?.arg?.end]
                );

                if (callKey) {
                    delete state.activeHttpCalls[callKey];
                }

                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.error,
                    message:
                        action?.payload?.message ??
                        action?.error?.message ??
                        AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.Error'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.dashboardInsulinUsage'),
                });

                state.loadingSemaphore--;
            })
            .addCase(fetchDashboardInsulinUsageTrend.pending, (state, action) => {
                state.loadingSemaphore++;

                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchDashboardInsulinUsageTrend,
                    [action.meta?.arg?.beg, action.meta?.arg?.end]
                );

                state.activeHttpCalls[callKey] = true;
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.info,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pending'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.dashboardInsulinUsageTrend'),
                });
            })
            .addCase(fetchDashboardInsulinUsageTrend.fulfilled, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchDashboardInsulinUsageTrend,
                    [action.meta?.arg?.beg, action.meta?.arg?.end]
                );

                if (callKey) {
                    delete state.activeHttpCalls[callKey];
                }

                if (action.payload) {
                    const currentInsulinUsage: IInsulinUsage = {
                        ...state.currentInsulinUsage,
                        ...action.payload.data.item,
                    } as any;

                    state.currentInsulinUsage = currentInsulinUsage;
                    state.errors[callKey] = UiHelper.CreateErrorMessage({
                        level: ConstantsHelper.ErrorLevels.success,
                        message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.success'),
                        title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.dashboardInsulinUsageTrend'),
                    });
                }

                state.loadingSemaphore--;
            })
            .addCase(fetchDashboardInsulinUsageTrend.rejected, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchDashboardInsulinUsageTrend,
                    [action.meta?.arg?.beg, action.meta?.arg?.end]
                );

                if (callKey) {
                    delete state.activeHttpCalls[callKey];
                }

                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.error,
                    message:
                        action?.payload?.message ??
                        action?.error?.message ??
                        AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.Error'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.dashboardInsulinUsageTrend'),
                });

                state.loadingSemaphore--;
            })
            .addCase(fetchDashboardSummary.pending, (state, action) => {
                state.loadingSemaphore++;

                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchDashboardSummary,
                    [action.meta?.arg?.beg, action.meta?.arg?.end]
                );

                state.activeHttpCalls[callKey] = true;
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.info,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pending'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.dashboardSummary'),
                });
            })
            .addCase(fetchDashboardSummary.fulfilled, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchDashboardSummary,
                    [action.meta?.arg?.beg, action.meta?.arg?.end]
                );

                if (callKey) {
                    delete state.activeHttpCalls[callKey];
                }

                if (action.payload) {
                    state.currentSummary = {
                        ...action.payload.data.item,
                        celebrationDays: action.payload.data.item.celebrationDays?.map((dayEntry) => {
                            const hasTimezoneChange = dayEntry.hasTimezoneChange;
                            return {
                                ...dayEntry,
                                percentage: dayEntry.percentage ?? 0,
                                hasTimezoneChange,
                            };
                        }),
                    };
                    state.errors[callKey] = UiHelper.CreateErrorMessage({
                        level: ConstantsHelper.ErrorLevels.success,
                        message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.success'),
                        title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.dashboardSummary'),
                    });
                }

                state.loadingSemaphore--;
            })
            .addCase(fetchDashboardSummary.rejected, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchDashboardSummary,
                    [action.meta?.arg?.beg, action.meta?.arg?.end]
                );

                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.error,
                    message:
                        action?.payload?.message ??
                        action?.error?.message ??
                        AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.Error'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.dashboardSummary'),
                });
                state.loadingSemaphore--;
            })
            .addCase(fetchDashboardSummaryGlucoseTrend.pending, (state, action) => {
                state.loadingSemaphore++;

                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchDashboardSummaryGlucoseTrend,
                    [action.meta?.arg?.beg, action.meta?.arg?.end]
                );

                state.activeHttpCalls[callKey] = true;
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.info,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pending'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.dashboardSummaryGlucoseTrend'),
                });
            })
            .addCase(fetchDashboardSummaryGlucoseTrend.fulfilled, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchDashboardSummaryGlucoseTrend,
                    [action.meta?.arg?.beg, action.meta?.arg?.end]
                );

                if (callKey) {
                    delete state.activeHttpCalls[callKey];
                }

                if (action.payload) {
                    state.currentSummaryGlucoseTrend = action.payload.data.item;
                    state.errors[callKey] = UiHelper.CreateErrorMessage({
                        level: ConstantsHelper.ErrorLevels.success,
                        message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.success'),
                        title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.dashboardSummaryGlucoseTrend'),
                    });
                }

                state.loadingSemaphore--;
            })
            .addCase(fetchDashboardSummaryGlucoseTrend.rejected, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchDashboardSummaryGlucoseTrend,
                    [action.meta?.arg?.beg, action.meta?.arg?.end]
                );

                if (callKey) {
                    delete state.activeHttpCalls[callKey];
                }
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.error,
                    message:
                        action?.payload?.message ??
                        action?.error?.message ??
                        AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.Error'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.dashboardSummaryGlucoseTrend'),
                });

                state.loadingSemaphore--;
            })
            .addCase(fetchDashboard.pending, (state, action) => {
                state.loadingSemaphore++;

                const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchDashboard, [
                    action.meta?.arg?.beg,
                    action.meta?.arg?.end,
                ]);

                state.activeHttpCalls[callKey] = true;
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.info,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pending'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.dashboard'),
                });
            })
            .addCase(fetchDashboard.fulfilled, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchDashboard, [
                    action.meta?.arg?.beg,
                    action.meta?.arg?.end,
                ]);

                if (callKey) {
                    delete state.activeHttpCalls[callKey];
                }

                if (action.payload) {
                    const insightIds: string[] = [];

                    if (action.payload.data.totalCount > 0) {
                        /**
                         * Scan low then high pattern groups
                         */
                        InsightHelper.PopulateInsightData(state, action.payload.data.items, insightIds);
                    }

                    state.currentDashboard = {
                        insightIds,
                    };

                    state.errors[callKey] = UiHelper.CreateErrorMessage({
                        level: ConstantsHelper.ErrorLevels.success,
                        message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.success'),
                        title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.dashboard'),
                    });
                }

                state.loadingSemaphore--;
            })
            .addCase(fetchDashboard.rejected, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchDashboard, [
                    action.meta?.arg?.beg,
                    action.meta?.arg?.end,
                ]);

                if (callKey) {
                    delete state.activeHttpCalls[callKey];
                }

                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.error,
                    message:
                        action?.payload?.message ??
                        action?.error?.message ??
                        AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.Error'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.dashboard'),
                });

                state.loadingSemaphore--;
            })
            .addCase(fetchInsightEventReadings.pending, (state, action) => {
                state.loadingSemaphore++;

                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchInsightEventReadings,
                    [action.meta?.arg?.insightId, action.meta?.arg?.eventId]
                );

                state.activeHttpCalls[callKey] = true;

                const currEntry = state.currentReadingCombo;

                state.currentReadingCombo = {
                    ...currEntry,
                    fetched: true,
                };
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.info,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pending'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.eventReadings'),
                });
            })
            .addCase(fetchInsightEventReadings.fulfilled, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchInsightEventReadings,
                    [action.meta?.arg?.insightId, action.meta?.arg?.eventId]
                );

                if (callKey) {
                    delete state.activeHttpCalls[callKey];
                }

                state.currentReadingCombo = EventHelper.OrderEventReadings(
                    action.payload.id,
                    state.cachedInsights[action.payload.id2],
                    state.readingsInfo.fillGaps,
                    {
                        ...state.currentReadingCombo,
                        ...action.payload?.data,
                    }
                );
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.success,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.success'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.eventReadings'),
                });
                state.loadingSemaphore--;
            })
            .addCase(fetchInsightEventReadings.rejected, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchInsightEventReadings,
                    [action.meta?.arg?.insightId, action.meta?.arg?.eventId]
                );

                if (callKey) {
                    delete state.activeHttpCalls[callKey];
                }
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.error,
                    message:
                        action?.payload?.message ??
                        action?.error?.message ??
                        AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.Error'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.eventReadings'),
                });
                state.loadingSemaphore--;
            })
            .addCase(fetchInsightEventBoluses.pending, (state, action) => {
                state.loadingSemaphore++;

                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchInsightEventBoluses,
                    [action.meta?.arg?.insightId, action.meta?.arg?.eventId]
                );

                state.activeHttpCalls[callKey] = true;

                const currEntry = state.currentReadingCombo;

                state.currentReadingCombo = {
                    ...currEntry,
                    fetched: true,
                };
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.info,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pending'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.eventBoluses'),
                });
            })
            .addCase(fetchInsightEventBoluses.fulfilled, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchInsightEventBoluses,
                    [action.meta?.arg?.insightId, action.meta?.arg?.eventId]
                );

                if (callKey) {
                    delete state.activeHttpCalls[callKey];
                }

                state.currentReadingCombo = EventHelper.OrderEventReadings(
                    action.payload.id,
                    state.cachedInsights[action.payload.id2],
                    state.readingsInfo.fillGaps,
                    {
                        ...state.currentReadingCombo,
                        ...action.payload?.data,
                    }
                );
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.success,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.success'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.eventBoluses'),
                });
                state.loadingSemaphore--;
            })
            .addCase(fetchInsightEventBoluses.rejected, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchInsightEventBoluses,
                    [action.meta?.arg?.insightId, action.meta?.arg?.eventId]
                );

                if (callKey) {
                    delete state.activeHttpCalls[callKey];
                }

                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.error,
                    message:
                        action?.payload?.message ??
                        action?.error?.message ??
                        AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.Error'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.eventBoluses'),
                });
                state.loadingSemaphore--;
            })
            .addCase(fetchInsightEventModes.pending, (state, action) => {
                state.loadingSemaphore++;

                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchInsightEventModes,
                    [action.meta?.arg?.insightId, action.meta?.arg?.eventId]
                );

                state.activeHttpCalls[callKey] = true;

                const currEntry = state.currentReadingCombo;

                state.currentReadingCombo = {
                    ...currEntry,
                    fetched: true,
                };
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.info,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pending'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.eventModes'),
                });
            })
            .addCase(fetchInsightEventModes.fulfilled, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchInsightEventModes,
                    [action.meta?.arg?.insightId, action.meta?.arg?.eventId]
                );

                if (callKey) {
                    delete state.activeHttpCalls[callKey];
                }

                state.currentReadingCombo = EventHelper.OrderEventReadings(
                    action.payload.id,
                    state.cachedInsights[action.payload.id2],
                    state.readingsInfo.fillGaps,
                    {
                        ...state.currentReadingCombo,
                        ...action.payload?.data,
                    }
                );
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.success,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.success'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.eventModes'),
                });
                state.loadingSemaphore--;
            })
            .addCase(fetchInsightEventModes.rejected, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.fetchInsightEventModes,
                    [action.meta?.arg?.insightId, action.meta?.arg?.eventId]
                );

                if (callKey) {
                    delete state.activeHttpCalls[callKey];
                }
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.error,
                    message:
                        action?.payload?.message ??
                        action?.error?.message ??
                        AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.Error'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.eventModes'),
                });
                state.loadingSemaphore--;
            })
            .addCase(fetchHcpReport.pending, (state, action) => {
                state.loadingSemaphore++;

                const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchHcpReport, [
                    `${action.meta?.arg?.numDays}`,
                ]);

                state.activeHttpCalls[callKey] = true;
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.info,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pending'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.reportPending'),
                });
            })
            .addCase(fetchHcpReport.fulfilled, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchHcpReport, [
                    `${action.meta?.arg?.numDays}`,
                ]);

                if (callKey) {
                    delete state.activeHttpCalls[callKey];
                }

                // download to new tab handler
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.success,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.hcpReportBody', {
                        dayCount: StringHelper.Pluralize(action.payload, 'day'),
                    }),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.hcpReportTitle'),
                    showAlways: true,
                    sticky: true,
                });
                state.loadingSemaphore--;
            })
            .addCase(fetchHcpReport.rejected, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchHcpReport, [
                    `${action.meta?.arg?.numDays}`,
                ]);

                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.error,
                    message:
                        action?.payload?.message ??
                        action?.error?.message ??
                        AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.Error'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.reportPending'),
                });
                state.loadingSemaphore--;
            })
            .addCase(fetchClinics.pending, (state) => {
                state.loadingSemaphore++;

                const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchClinics);

                state.activeHttpCalls[callKey] = true;
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.info,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pending'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.settings'),
                });
            })
            .addCase(fetchClinics.fulfilled, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchClinics);
                const payload: Clinic[] = action.payload;

                if (callKey) {
                    delete state.activeHttpCalls[callKey];
                }

                state.clinics = payload;

                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.success,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.success'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.settings'),
                });
                state.loadingSemaphore--;
            })
            .addCase(fetchClinics.rejected, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchClinics);

                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.error,
                    message:
                        action?.payload?.message ??
                        action?.error?.message ??
                        AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.Error'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.settings'),
                });
                state.loadingSemaphore--;
            })
            .addCase(acceptClinicInvitation.pending, (state) => {
                state.loadingSemaphore++;

                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.acceptClinicInvitation
                );

                state.activeHttpCalls[callKey] = true;
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.info,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pending'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.settings'),
                });
            })
            .addCase(acceptClinicInvitation.fulfilled, (state) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.acceptClinicInvitation
                );

                if (callKey) {
                    delete state.activeHttpCalls[callKey];
                }

                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.success,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.success'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.settings'),
                });
                state.loadingSemaphore--;
            })
            .addCase(acceptClinicInvitation.rejected, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.acceptClinicInvitation
                );

                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.error,
                    message:
                        action?.payload?.message ??
                        action?.error?.message ??
                        AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.Error'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.settings'),
                });
                state.loadingSemaphore--;
            })
            .addCase(denyClinicInvitation.pending, (state) => {
                state.loadingSemaphore++;

                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.denyClinicInvitation
                );

                state.activeHttpCalls[callKey] = true;
                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.info,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.pending'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.settings'),
                });
            })
            .addCase(denyClinicInvitation.fulfilled, (state) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.denyClinicInvitation
                );

                if (callKey) {
                    delete state.activeHttpCalls[callKey];
                }

                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.success,
                    message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.success'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.settings'),
                });
                state.loadingSemaphore--;
            })
            .addCase(denyClinicInvitation.rejected, (state, action) => {
                const callKey = UtilityHelper.MakeHttpCallKey(
                    ConstantsHelper.httpCallAndErrorKeys.denyClinicInvitation
                );

                state.errors[callKey] = UiHelper.CreateErrorMessage({
                    level: ConstantsHelper.ErrorLevels.error,
                    message:
                        action?.payload?.message ??
                        action?.error?.message ??
                        AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.Error'),
                    title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.settings'),
                });
                state.loadingSemaphore--;
            });
    },
});

export const {
    actions: {
        allReportsRowCnt: allReportsRowCntActionCreator,
        anI18NextLib: anI18NextLibActionCreator,
        contributingEventMetaData: contributingEventMetaDataActionCreator,
        dashboardMetaData: dashboardMetaDataActionCreator,
        endpointUrlPrefix: endpointUrlPrefixActionCreator,
        error: errorActionCreator,
        insightId: insightIdActionCreator,
        loading: loadingActionCreator,
        pollProfile: pollProfileActionCreator,
        setActiveHttpCalls,
        setMessage,
        setReadingsInfo: setReadingsInfoActionCreator,
        setReloadInProgress,
        signInUrl: signInUrlCreator,
        updatePatient: updatePatientActionCreator,
    },
} = appSlice;

export default appSlice.reducer;
