import { useTranslation } from 'react-i18next';
import { Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import { useDispatch, useSelector } from 'react-redux';
import { OktaAuth } from '@okta/okta-auth-js';
import React, { useCallback, useState, useEffect } from 'react';
import i18n from 'i18next';
import clsx from 'clsx';

import MenuLabel from './MenuLabel';

import { IMenuEntry } from '../../types';
import { dashboardMetaDataActionCreator, anI18NextLibActionCreator } from '../../store/app/app.slice';
import { sendAzureEvent } from '../../helpers/appInsights';
import { RootState } from '../../store/store';
import { setAnchorAllReports, setIsMenuOpen } from '../../store/ui/ui.slice';
import { IAppState } from '../../store/app/app.types';
import styleGeneral from '../../styles/general.module.scss';
import omnipodDiscover from '../../assets/images/discover-logo.svg';
import MenuHelper from '../../helpers/MenuHelper';
import UiHelper from '../../helpers/UiHelper';
import ConstantsHelper from '../../helpers/ConstantsHelper';
import UtilityHelper from '../../helpers/UtilityHelper';
import SystemHelper from '../../helpers/SystemHelper';
import AnI18NextLibHelper from '../../helpers/AnI18NextLibHelper';

const signOut = async (keyToAllowSignOut: string, oktaAuth: OktaAuth) => {
    if (UtilityHelper.SettingGet(keyToAllowSignOut) !== '1') {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        setTimeout(async () => signOut(keyToAllowSignOut, oktaAuth), 1000);
    } else {
        sendAzureEvent(ConstantsHelper.analyticsEventNames.LOG_OUT);
        await oktaAuth.signOut();
    }
};

const { loginURL } = MenuHelper.Init();

interface IMenuProps {
    app: IAppState;
    menuEntries: IMenuEntry[];
}

function Menu({ app, menuEntries }: Readonly<IMenuProps>) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { oktaAuth } = useOktaAuth();

    const isMenuOpen = useSelector((state: RootState) => state.ui.isMenuOpen);
    const isMenuVisible = useSelector((state: RootState) => state.ui.isMenuVisible);

    const [orientation, setOrientation] = useState<'ltr' | 'rtl'>('ltr');
    const browserInfo = SystemHelper.GetBrowserInfo();
    const notIsProd = !SystemHelper.IsProd();
    const isUnsupportedBrowser = !browserInfo.supported;

    // const control = home.control ?? ({} as IControl);

    const handleLogoClick = () => {
        dispatch(setAnchorAllReports({ anchorAllReports: undefined }));

        dispatch(
            dashboardMetaDataActionCreator({
                dashboardBeg: undefined,
                dashboardEnd: undefined,
                dashboardHasReferrer: undefined,
            })
        );

        dispatch(setIsMenuOpen({ isMenuOpen: false }));
    };

    const toggleMenu = () => {
        dispatch(setIsMenuOpen({ isMenuOpen: !isMenuOpen }));
    };

    const handleNavItemClick = () => {
        toggleMenu();
    };

    const handleSignInClick = () => {
        MenuHelper.redirectToLogin(loginURL);
    };

    const handleSignOutClick = useCallback(async () => {
        const keyToAllowSignOut = ConstantsHelper.IntersessionKeys.signOutCanProceed;

        UiHelper.SignOutArm(dispatch, app);

        /* CAUTION:
            Do not dispatch any Redux actions before signout has been initiated as these actions
            may trigger a component refresh while signout has not been completed.  This could
            sometimes cause the session access token to refresh itself and in effect cancel the
            signout.
        */
        await signOut(keyToAllowSignOut, oktaAuth);
    }, [dispatch, app, oktaAuth]);

    const handleMenuEntryClick = (menuEntryKey: MenuHelper) => {
        switch (menuEntryKey) {
            case MenuHelper.MenuEntrySignIn.key:
                return handleSignInClick();
            case MenuHelper.MenuEntrySignOut.key:
                return handleSignOutClick();
            default:
                return handleNavItemClick();
        }
    };

    const setLanguage = (language: string) => {
        i18n.changeLanguage(language);
        dispatch(anI18NextLibActionCreator({ anI18Nextlib: AnI18NextLibHelper.loadLang(language) }));
    };

    useEffect(() => {
        if (orientation === 'rtl') {
            document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
        } else {
            document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr');
        }
    }, [orientation]);

    return (
        <div className={styleGeneral.main}>
            {notIsProd && <div className={styleGeneral.nonProdUse}>{t('nonProdUse')}</div>}

            {isUnsupportedBrowser && <div className={styleGeneral.unsupportedBrowser}>{t('unsupportedBrowser')}</div>}

            <Navbar
                className={clsx(styleGeneral.navbarBox, 'navbar', 'navbar-light', 'bg-light')}
                collapseOnSelect
                expand={false}
                bg="light"
                variant="light"
            >
                <Tooltip title={t('goToHomeScreen')}>
                    <Navbar.Brand
                        as={Link}
                        to="/"
                        className={clsx(styleGeneral.navbarBrand, styleGeneral.banner)}
                        onClick={handleLogoClick}
                    >
                        <img className={styleGeneral.navbarLogo} src={omnipodDiscover} alt="Omnipod Discover™" />
                    </Navbar.Brand>
                </Tooltip>

                {isMenuVisible && (
                    <Navbar.Toggle
                        aria-controls="responsive-navbar-nav"
                        data-testid="menu-button"
                        className={clsx(styleGeneral.navbarHamburger, styleGeneral.layerTopmost)}
                        onClick={toggleMenu}
                    >
                        <div className={styleGeneral.menu}>
                            <div className={clsx(styleGeneral.bar1, isMenuOpen && styleGeneral.bar1Change)}></div>
                            <div className={clsx(styleGeneral.bar2, isMenuOpen && styleGeneral.bar2Change)}></div>
                            <div className={clsx(styleGeneral.bar3, isMenuOpen && styleGeneral.bar3Change)}></div>
                        </div>
                    </Navbar.Toggle>
                )}

                <Navbar.Collapse
                    id="responsive-navbar-nav"
                    className={clsx(styleGeneral.layerTopmost, styleGeneral.scrollable, styleGeneral.menuFrame)}
                >
                    <Nav className="mr-auto" data-testid="main-menu">
                        {menuEntries
                            ?.filter((menuEntry) => menuEntry.label?.length > 0)
                            ?.map((menuEntry, idx: number) => {
                                return (
                                    <span key={`navSpan${idx}`}>
                                        {menuEntry?.preSeparate && idx > 0 && (
                                            <span key={`navSpanT${idx}`} className={styleGeneral.menuItemPreSeparate} />
                                        )}

                                        <Nav.Link
                                            data-testid={menuEntry?.label?.replace(' ', '_')}
                                            key={`navRow${idx}`}
                                            eventKey={`navLink${idx}`}
                                            as={Link}
                                            to={menuEntry.path ?? ''}
                                            onClick={() => handleMenuEntryClick(menuEntry.key)}
                                        >
                                            <MenuLabel menuEntry={menuEntry} />
                                        </Nav.Link>

                                        {menuEntry?.postSeparate && (
                                            <span key={`navSpanB${idx}`} className={styleGeneral.menuItemPreSeparate} />
                                        )}
                                    </span>
                                );
                            })}
                    </Nav>
                    <div>
                        <h5>Language</h5>
                        <button type="button" onClick={() => setLanguage('ar')}>
                            ar
                        </button>
                        <button type="button" onClick={() => setLanguage('de')}>
                            de
                        </button>
                        <button type="button" onClick={() => setLanguage('es')}>
                            es
                        </button>
                        <button type="button" onClick={() => setLanguage('en')}>
                            en
                        </button>
                        <button type="button" onClick={() => setLanguage('fi')}>
                            fi
                        </button>
                        <button type="button" onClick={() => setLanguage('he')}>
                            he
                        </button>
                        <button type="button" onClick={() => setLanguage('ja')}>
                            ja
                        </button>
                        <button type="button" onClick={() => setLanguage('pt')}>
                            pt
                        </button>
                        <button type="button" onClick={() => setLanguage('zh')}>
                            ch
                        </button>
                    </div>
                    <div>
                        <h4>Orientation</h4>
                        <button type="button" onClick={() => setOrientation(orientation === 'ltr' ? 'rtl' : 'ltr')}>
                            {orientation === 'ltr' ? 'RTL' : 'LTR'}
                        </button>
                    </div>
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
}

export default Menu;
