import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';

import { sendAzureEvent } from 'src/helpers/appInsights';

import { dashboardMetaDataActionCreator, insightIdActionCreator, setMessage } from '../store/app/app.slice';
import { Insight3, Insight3GlucoseTrend } from '../model/models';
import { setValuesFromStateInLocalStorage } from '../helpers/SessionHelper';
import {
    setAnchorInsight,
    setAnchorLoyaltyCongrats,
    setAnchorLoyaltyInsulin,
    setCarouselIndex,
} from '../store/ui/ui.slice';
import { RootState } from '../store/store';
import { IAppState, IDashboardCache } from '../store/app/app.types';
import { appInitialState } from '../store/app/app.initialState';
import BtnBack from '../components/common/btnBack';
import SystemHelper from '../helpers/SystemHelper';
import DateTimeHelper from '../helpers/DateTimeHelper';
import NavigationHelper from '../helpers/NavigationHelper';
import styleGeneral from '../styles/general.module.scss';
import styleGuide from '../styles/styleGuide.module.scss';
import InsightsFrame from '../components/dashboard/insightsFrame';
import UiHelper from '../helpers/UiHelper';
import ConstantsHelper from '../helpers/ConstantsHelper';
import UtilityHelper from '../helpers/UtilityHelper';
import ContentWrapperAll from '../components/common/contentWrappers/ContentWrapperAll';

const getInsightInDateRange = (
    dispatch: any,
    appHandle: IAppState,
    beg: string,
    end: string,
    dashboardHasReferrerInsight: boolean
) => {
    if (
        !(appHandle.dashboardBeg === beg && appHandle.dashboardEnd === end) ||
        dashboardHasReferrerInsight ||
        (appHandle.dashboardBeg === beg && appHandle.dashboardEnd === end && !appHandle.currentDashboard?.insightIds)
    ) {
        const payload = UiHelper.GetInsightsDateRange(dispatch, appHandle, beg, end, dashboardHasReferrerInsight);

        if (payload.beg && payload.end) {
            UiHelper.ClearErrors(dispatch);
            UiHelper.FetchDashboard(dispatch, payload.beg, payload.end, appHandle);
            UiHelper.FetchDashboardInsulinUsage(dispatch, payload.beg, payload.end, appHandle);
            UiHelper.FetchDashboardInsulinUsageTrend(dispatch, payload.beg, payload.end, appHandle);
            UiHelper.FetchDashboardSummary(dispatch, payload.beg, payload.end, appHandle);
            UiHelper.FetchDashboardSummaryGlucoseTrend(dispatch, payload.beg, payload.end, appHandle);
        }
    }
};

const armInsightEvents = (
    dispatch: any,
    app: IAppState,
    { insightId, anchorInsight, patternType, insightType }: any
): void => {
    sendAzureEvent(ConstantsHelper.analyticsEventNames.CLICK_INSIGHT_DETAILS, {
        insightId,
        insightType,
        patternType,
    });

    dispatch(insightIdActionCreator({ insightId }));
    dispatch(setMessage({ message: undefined }));
    dispatch(setAnchorInsight({ anchorInsight }));

    setValuesFromStateInLocalStorage({ [ConstantsHelper.IntersessionKeys.insightId]: app.insightId });
};

const cbCarouselSetIndexFn = (dispatch: any, carouselIndex: number, { index }: { index: number }) => {
    // Note:
    //     There seems to be bug in the carousel where double-clicking on the selector arrows will
    //     keep firing index changed event where both old and new indices are equal.
    //     This results in an infinite loop that is broken with this conditional check
    if (carouselIndex !== index) {
        dispatch(setCarouselIndex({ index }));
    }
};

const renderInsights = ({
    dispatch,
    app,
    handleScroll,
    scrollActive1,
    scrollActive2,
    pwdName,
    dashboard,
    summary,
    summaryGlucoseTrend,
    carouselIndex,
}: {
    dispatch: any;
    app: IAppState;
    handleScroll: any;
    scrollActive1: boolean;
    scrollActive2: boolean;
    pwdName: string;
    dashboard: IDashboardCache;
    summary: Insight3;
    summaryGlucoseTrend: Insight3GlucoseTrend;
    carouselIndex: number;
}) => (
    <div className={styleGeneral.body} onScroll={(event) => handleScroll(event)}>
        {app.dashboardHasReferrer && (
            <div className={styleGeneral.stickToTopBack}>
                <BtnBack url="/all-reports" />
            </div>
        )}
        <div
            className={clsx(
                styleGuide.report,
                styleGuide.solidBgWhite,
                app.dashboardHasReferrer ? styleGeneral.stickToTopLevel2 : styleGeneral.stickToTopLevel1
            )}
            data-testid="insights"
        >
            <div
                className={clsx(
                    styleGuide.header,
                    styleGuide.solidBgWhite,
                    styleGeneral.dashboardZoomInScroll1OffScroll2Off
                )}
            >
                <div
                    className={clsx(
                        app.dashboardHasReferrer ? styleGuide.patientNameAlt : styleGuide.patientName,
                        scrollActive1 && styleGeneral.inScroll2
                    )}
                >
                    {pwdName.length > 0 ? pwdName : '--'}
                </div>
                <div className={clsx(styleGuide.dateRange, scrollActive1 && styleGuide.dateRangeShort)}>
                    <div className={clsx(styleGuide.caption, scrollActive1 && styleGeneral.inScroll)}>
                        {DateTimeHelper.FormatDateRange(app.dashboardEnd)}
                    </div>
                </div>
            </div>
        </div>

        <InsightsFrame
            app={app}
            scrollActive1={scrollActive1}
            scrollActive2={scrollActive2}
            dashboard={dashboard}
            summary={summary}
            summaryGlucoseTrend={summaryGlucoseTrend}
            cbClickFn={(clickParams: any) => armInsightEvents(dispatch, app, clickParams)}
            cbCarouselSetIndexFn={(setIndexParams: any) =>
                cbCarouselSetIndexFn(dispatch, carouselIndex, setIndexParams)
            }
            cbLoyaltyCongrats={(anchor: string) =>
                dispatch(setAnchorLoyaltyCongrats({ anchorLoyaltyCongrats: anchor }))
            }
            cbLoyaltyInsulin={(anchor: string) => dispatch(setAnchorLoyaltyInsulin({ anchorLoyaltyInsulin: anchor }))}
        />
    </div>
);

function Dashboard(props: any) {
    const dispatch = useDispatch();

    const anchorInsight = useSelector((state: RootState) => state.ui.anchorInsight);
    const anchorLoyaltyCongrats = useSelector((state: RootState) => state.ui.anchorLoyaltyCongrats);
    const anchorLoyaltyInsulin = useSelector((state: RootState) => state.ui.anchorLoyaltyInsulin);
    const carouselIndex = useSelector((state: RootState) => state.ui.carouselIndex);
    const app = SystemHelper.GetCleanState(
        useSelector((state: RootState) => state.app),
        appInitialState
    );
    const oktaData = useSelector((state: RootState) => state.authentication.oktaData);

    const patient = app.patient;
    const { dashboardBeg, dashboardEnd, dashboardHasReferrer } = props?.match?.params ?? {};

    useEffect(() => {
        if (
            app.allLoaded &&
            !(
                app.dashboardBeg === dashboardBeg &&
                app.dashboardEnd === dashboardEnd &&
                app.dashboardHasReferrer === (dashboardHasReferrer === 'true')
            )
        ) {
            dispatch(
                dashboardMetaDataActionCreator({
                    dashboardBeg: dashboardBeg,
                    dashboardEnd: dashboardEnd,
                    dashboardHasReferrer: dashboardHasReferrer === 'true',
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [app.allLoaded, dashboardBeg, dashboardEnd, dashboardHasReferrer]);

    const dashboard = app.currentDashboard;
    const summary = app.currentSummary;
    const summaryGlucoseTrend = app.currentSummaryGlucoseTrend;
    const [scrollActive1, setScrollActive1] = useState(false);
    const [scrollActive2, setScrollActive2] = useState(false);
    const handleScroll = (event: any) =>
        NavigationHelper.HandleScroll(
            12,
            scrollActive1,
            scrollActive2,
            setScrollActive1,
            setScrollActive2,
            event?.target?.scrollTop
        );

    useLayoutEffect(() => {
        if (oktaData.userName) {
            SystemHelper.AppInsightsEvent(app, 'Dashboard()', 'Mounted');
            sendAzureEvent(ConstantsHelper.analyticsEventNames.VIEW_DASHBOARD);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (patient.receivedData && !dashboard?.insightIds && app.allLoaded) {
            const hasReferrer = UtilityHelper.IsNull(dashboardHasReferrer)
                ? app.dashboardHasReferrer
                : dashboardHasReferrer === 'true';
            const dashboardEndFallback: string = app.dashboardEnd;
            const dashboardEnd1: string =
                app.dashboardEnd ??
                dashboardEndFallback ??
                DateTimeHelper.GetDateNearestPastSaturday(dashboardEndFallback, 6);
            let dateRangeCurr = DateTimeHelper.GetIsoWeekRange(dashboardEnd1, 0);

            if (!dateRangeCurr.end) {
                dateRangeCurr = DateTimeHelper.GetIsoWeekRange(
                    DateTimeHelper.GetDateNearestPastSaturday(dashboardEndFallback, 0),
                    0
                );
            }
            getInsightInDateRange(dispatch, app, dateRangeCurr.beg, dateRangeCurr.end, hasReferrer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        patient.receivedData,
        app.dashboardEnd,
        app.allLoaded,
        app.patient?.confidentialityAgreementAccepted,
        app.patient?.eulaAccepted,
    ]);

    useEffect(() => {
        if (anchorInsight && dashboard?.insightIds) {
            NavigationHelper.ScrollIntoView(anchorInsight, () =>
                dispatch(setAnchorInsight({ anchorInsight: undefined }))
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [anchorInsight, dashboard?.insightIds]);

    useEffect(() => {
        if (anchorLoyaltyCongrats && dashboard?.insightIds) {
            NavigationHelper.ScrollIntoView(anchorLoyaltyCongrats, () =>
                dispatch(setAnchorLoyaltyCongrats({ anchorLoyaltyCongrats }))
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [anchorLoyaltyCongrats, dashboard?.insightIds]);

    useEffect(() => {
        if (anchorLoyaltyInsulin && dashboard?.insightIds) {
            NavigationHelper.ScrollIntoView(anchorLoyaltyInsulin, () =>
                dispatch(setAnchorLoyaltyInsulin({ anchorLoyaltyInsulin }))
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [anchorLoyaltyInsulin, dashboard?.insightIds]);

    // TODO:  Add tests for this effect under tech debt work
    useEffect(() => {
        if (
            !UtilityHelper.IsNull(app.dashboardBeg) &&
            !UtilityHelper.IsNull(app.dashboardEnd) &&
            (app.dashboardEnd !== app.currentLearningMaterialProgress.id ||
                DateTimeHelper.GetIsoNow() > app.currentLearningMaterialProgress.expires)
        ) {
            UiHelper.FetchLearningMaterialOp5Progress(dispatch, app.dashboardBeg, app.dashboardEnd, app);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [app.dashboardBeg, app.dashboardEnd, app.patient?.confidentialityAgreementAccepted, app.patient?.eulaAccepted]);

    const pwdName = useMemo(
        () => UtilityHelper.GetFullName(app.patient?.firstName, app.patient?.lastName),
        [app.patient?.firstName, app.patient?.lastName]
    );

    return (
        <ContentWrapperAll app={app} dispatch={dispatch} showIfInvalidEnrollment={false}>
            {dashboard?.insightIds &&
                renderInsights({
                    dispatch,
                    app,
                    handleScroll,
                    scrollActive1,
                    scrollActive2,
                    pwdName,
                    dashboard,
                    summary,
                    summaryGlucoseTrend,
                    carouselIndex,
                })}
        </ContentWrapperAll>
    );
}

export default Dashboard;
