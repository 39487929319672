import React from 'react';
import { useOktaAuth } from '@okta/okta-react';

import Menu from './menu';

import { IAppState } from '../../store/app/app.types';
import 'bootstrap/dist/css/bootstrap.css'; // Import precompiled Bootstrap css
import '../../app/App.scss';
import MenuHelper from '../../helpers/MenuHelper';

function Header({ app }: Readonly<{ app: IAppState }>) {
    const authState = useOktaAuth()?.authState;
    const menuEntries = MenuHelper.MenuEntries.filter(
        (e) =>
            (authState?.isAuthenticated && !e.hidden && !e.disabled) ||
            (!authState?.isAuthenticated && e.key === MenuHelper.MenuEntrySignIn.key)
    );

    return <Menu app={app} menuEntries={menuEntries} />;
}

export default Header;
