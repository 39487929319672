import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import React, { useEffect } from 'react';

import { RootState } from '../store/store';
import { appInitialState } from '../store/app/app.initialState';
import SystemHelper from '../helpers/SystemHelper';
import styleGeneral from '../styles/general.module.scss';
import styleGuide from '../styles/styleGuide.module.scss';
import ContentWrapperAll from '../components/common/contentWrappers/ContentWrapperAll';

function Help() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const app = SystemHelper.GetCleanState(
        useSelector((state: RootState) => state.app),
        appInitialState
    );
    const isPauseItEnabled = SystemHelper.IsPauseItEnabled();

    useEffect(() => {
        SystemHelper.AppInsightsEvent(app, 'Help()', 'Mounted');
    }, [app]);

    return (
        <ContentWrapperAll app={app} dispatch={dispatch} showIfInvalidEnrollment>
            <div className={clsx(styleGeneral.body, styleGuide.help)}>
                <div className={clsx(styleGuide.header)}>
                    <div className={clsx(styleGuide.heading)}>{t('help.heading')}</div>
                </div>

                <div className={styleGuide.helpCard}>
                    <div className={styleGuide.whiteBackground}>
                        <div className={styleGuide.cardTitle}>{t('help.customerCare')}</div>
                        {isPauseItEnabled ? (
                            <div className={styleGuide.linkWrapper}>
                                <a
                                    className={styleGeneral.link}
                                    href={SystemHelper.GetRuntimeConfig('REACT_APP_CUSTOMER_CARE_EMAIL_LINK')}
                                    data-testid={'costumerCare-email'}
                                >
                                    {SystemHelper.GetRuntimeConfig('REACT_APP_CUSTOMER_CARE_EMAIL_LABEL')}
                                </a>
                            </div>
                        ) : (
                            <>
                                <div className={styleGuide.linkWrapper}>
                                    <a
                                        className={clsx(styleGeneral.link, styleGeneral.link_underline)}
                                        data-testid="customer-care-phone-number"
                                        href={SystemHelper.GetRuntimeConfig('REACT_APP_HELP_PHONE_NUMBER_LINK')}
                                    >
                                        {SystemHelper.GetRuntimeConfig('REACT_APP_HELP_PHONE_NUMBER_LABEL')}
                                    </a>
                                </div>
                                <div className={styleGuide.linkWrapper}>
                                    <a
                                        className={clsx(styleGeneral.link, styleGeneral.link_underline)}
                                        data-testid="contact-us-link"
                                        href={SystemHelper.GetRuntimeConfig('REACT_APP_HELP_CONTACT_US_URL_LINK')}
                                        target="__CONTACT_US__"
                                    >
                                        {SystemHelper.GetRuntimeConfig('REACT_APP_HELP_CONTACT_US_URL_LABEL')}
                                    </a>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                {!isPauseItEnabled && (
                    <div className={styleGuide.helpCard}>
                        <div className={styleGuide.whiteBackground}>
                            <div className={styleGuide.cardTitle}>{t('help.podderCentral')}</div>
                            <div className={styleGuide.cardDescription}> {t('help.podderCentralDescription')}</div>
                            <a
                                className={clsx(styleGeneral.link, styleGeneral.link_underline)}
                                href={SystemHelper.GetRuntimeConfig('REACT_APP_PODDER_CENTRAL_URL_LINK')}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {SystemHelper.GetRuntimeConfig('REACT_APP_PODDER_CENTRAL_URL_LINK')}
                            </a>
                        </div>
                    </div>
                )}
                <div className={styleGuide.helpCard}>
                    <div className={styleGuide.whiteBackground}>
                        <div className={styleGuide.cardTitle}>{t('help.userGuide')}</div>
                        <div className={clsx(styleGuide.linkWrapper, styleGuide.podderText)}>
                            <a
                                data-testid={'userGuide-link'}
                                className={clsx(styleGeneral.link, !isPauseItEnabled && styleGeneral.link_underline)}
                                href={SystemHelper.GetRuntimeConfig('REACT_APP_USER_GUIDE_URL')}
                                target="__GUIDE__"
                                download
                            >
                                {t('help.omnipodDiscover')}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </ContentWrapperAll>
    );
}

export default Help;
