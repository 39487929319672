import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import React, { useEffect } from 'react';

import { appInitialState } from '../store/app/app.initialState';
import { RootState } from '../store/store';
import SystemHelper from '../helpers/SystemHelper';
import UiHelper from '../helpers/UiHelper';
import styleGeneral from '../styles/general.module.scss';
import styleGuide from '../styles/styleGuide.module.scss';
import ContentWrapperAll from '../components/common/contentWrappers/ContentWrapperAll';
import iconHeadquarters from '../assets/images/headquarters-icon.svg';
import logoInsulet from '../assets/images/insulet-logo.svg';
import tobyTheTurtle from '../assets/images/toby-the-turtle.png';

function About() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const app = SystemHelper.GetCleanState(
        useSelector((state: RootState) => state.app),
        appInitialState
    );
    const isPauseItEnabled = SystemHelper.IsPauseItEnabled();
    const streetAddress = SystemHelper.GetRuntimeConfig('REACT_APP_ABOUT_COMPANY_ADDRESS');

    useEffect(() => {
        SystemHelper.AppInsightsEvent(app, 'About()', 'Mounted');
    }, [app]);
    useEffect(() => {
        if (!app.patient.receivedData) {
            UiHelper.FetchBackEndId(dispatch, app);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [app.patient.receivedData, app.patient?.confidentialityAgreementAccepted, app.patient?.eulaAccepted]);

    return (
        <ContentWrapperAll app={app} dispatch={dispatch} showIfInvalidEnrollment>
            <div className={styleGeneral.body}>
                <div className={styleGuide.about}>
                    <div className={styleGuide.heading}>{t('about.title')}</div>
                    <div className={styleGuide.body}>
                        <div className={styleGuide.group}>
                            <div className={styleGuide.label}>{t('constants.appName')}</div>
                            <div className={styleGuide.label}>{t('about.subTitle')}</div>
                        </div>
                        <div className={styleGuide.group}>
                            <div className={styleGuide.label}>{t('about.version')}</div>
                            <div className={styleGuide.value}>
                                {SystemHelper.GetRuntimeConfig('REACT_APP_VERSION', '--')}
                            </div>
                        </div>
                        <div className={styleGuide.group}>
                            <div className={styleGuide.label}>{t('about.udi')}</div>
                            <div className={styleGuide.value}>
                                (01){SystemHelper.GetRuntimeConfig('REACT_APP_DEVICE_IDENTIFIER', '--')}(10)
                                {SystemHelper.GetRuntimeConfig('REACT_APP_VERSION', '--')}
                            </div>
                        </div>
                        <div className={styleGuide.groupAlt}>
                            <a
                                className={clsx(styleGeneral.link, styleGeneral.link_underline)}
                                href={SystemHelper.GetRuntimeConfig('REACT_APP_ABOUT_URL_LEGAL')}
                                target="__EULA__"
                            >
                                {t('about.eulaInformation')}
                            </a>
                        </div>
                        <div className={styleGuide.groupAlt}>
                            <a
                                className={clsx(styleGeneral.link, styleGeneral.link_underline)}
                                href={SystemHelper.GetRuntimeConfig('REACT_APP_ABOUT_URL_COOKIE_POLICY')}
                                target="__COOKIE__"
                            >
                                {t('about.cookiePolicy')}
                            </a>
                        </div>
                    </div>
                    <div className={styleGuide.body} data-testid={'aboutAddress-section'}>
                        <div className={styleGuide.groupAlt}>
                            <div className={styleGeneral.table}>
                                <div className={styleGeneral.row}>
                                    <div className={styleGuide.image}>
                                        <img
                                            className={styleGuide.insuletLogo}
                                            src={logoInsulet}
                                            alt={t('about.insuletLogo')}
                                        />
                                    </div>
                                    <div className={clsx(styleGeneral.cell, styleGuide.left)}>
                                        <div className={styleGuide.image}>
                                            <img src={iconHeadquarters} alt={t('about.headquarters')} />
                                        </div>
                                    </div>
                                    <div className={clsx(styleGeneral.cell, styleGuide.right)}>
                                        <div className={styleGuide.text}>
                                            <div>{SystemHelper.GetRuntimeConfig('REACT_APP_ABOUT_COMPANY_NAME')}</div>
                                            <div>
                                                <a
                                                    className={clsx(styleGuide.link)}
                                                    href={`http://maps.google.com/?q=${streetAddress}`}
                                                    target="__STREET_ADDRESS__"
                                                >
                                                    {streetAddress}
                                                </a>
                                            </div>
                                            {!isPauseItEnabled && (
                                                <>
                                                    <div>
                                                        <a
                                                            className={clsx(styleGuide.link)}
                                                            data-testid="phone-number-1"
                                                            href={SystemHelper.GetRuntimeConfig(
                                                                'REACT_APP_ABOUT_PHONE_NUMBER1_LINK'
                                                            )}
                                                        >
                                                            {SystemHelper.GetRuntimeConfig(
                                                                'REACT_APP_ABOUT_PHONE_NUMBER1_LABEL'
                                                            )}
                                                        </a>
                                                    </div>
                                                    <div>
                                                        <a
                                                            className={clsx(styleGuide.link)}
                                                            data-testid="phone-number-2"
                                                            href={SystemHelper.GetRuntimeConfig(
                                                                'REACT_APP_ABOUT_PHONE_NUMBER2_LINK'
                                                            )}
                                                        >
                                                            {SystemHelper.GetRuntimeConfig(
                                                                'REACT_APP_ABOUT_PHONE_NUMBER2_LABEL'
                                                            )}
                                                        </a>
                                                    </div>
                                                </>
                                            )}
                                            <div>
                                                <a
                                                    className={clsx(styleGuide.link)}
                                                    href={SystemHelper.GetRuntimeConfig('REACT_APP_ABOUT_URL_LINK')}
                                                    target="__COMPANY_HOME__"
                                                >
                                                    {SystemHelper.GetRuntimeConfig('REACT_APP_ABOUT_URL_LABEL')}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={clsx(styleGuide.body, styleGuide.noPadding)} data-testid={'tobyTurtle-section'}>
                        <div className={styleGuide.horizontal}>
                            <div className={styleGuide.text}>
                                <div className={styleGuide.label}>{t('about.tobyGreeting')}</div>
                                <div className={styleGuide.label}>{t('about.toby')}</div>
                            </div>
                            <img src={tobyTheTurtle} alt={t('about.tobyTheTurtle')} />
                        </div>
                    </div>
                </div>
            </div>
        </ContentWrapperAll>
    );
}

export default About;
