import { useSelector } from 'react-redux';
import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';

import { sendAzureEvent } from '../../../helpers/appInsights';
import { ICallback, IDateTimeFormats } from '../../../types';
import { IAppState } from '../../../store/app/app.types';
import { RootState } from '../../../store/store';
import BtnPrimary from '../btnPrimary';
import styleGeneral from '../../../styles/general.module.scss';
import styleGuide from '../../../styles/styleGuide.module.scss';
import ConstantsHelper from '../../../helpers/ConstantsHelper';
import DateTimeHelper from '../../../helpers/DateTimeHelper';
import NavigationHelper from '../../../helpers/NavigationHelper';
import SystemHelper from '../../../helpers/SystemHelper';
import UtilityHelper from '../../../helpers/UtilityHelper';
import UiHelper from '../../../helpers/UiHelper';
import CheckSingle from '../checkSingle';

const idBtnAgree = 'idBtnAgree';

function ContentWrapperEulaAndConfAgr({ app, closeCb }: { app: IAppState; closeCb: ICallback<void> }) {
    const translate = (key: string, subs?: any) => UiHelper.Translate(app, key, subs);

    const [check1, setCheck1] = useState(false);
    const [check2, setCheck2] = useState(false);

    const oktaData = useSelector((state: RootState) => state.authentication.oktaData);

    const pwdName = useMemo(
        () => UtilityHelper.GetFullName(app.patient?.firstName, app.patient?.lastName),
        [app.patient?.firstName, app.patient?.lastName]
    );
    const setCheck = (setterFn: any, valueToSet: boolean, eventName: string, eventPayload: any) => {
        setterFn(valueToSet);
        NavigationHelper.ScrollIntoView(idBtnAgree);
        sendAzureEvent(eventName, eventPayload);
    };
    const cbAccept = () => {
        closeCb();
        sendAzureEvent(ConstantsHelper.analyticsEventNames.EULA_AND_CONF_AGR_ACCEPT);
    };
    const handleEulaAndConfAgrCaptionCb = (
        translateKey1: string,
        translateKey2: string,
        translateKey3: string,
        urlConfigKey: string,
        urlTargetName: string,
        testId: string,
        analyticsEventName: string
    ) => (
        <span className={styleGuide.checkBoxLabel}>
            {translate(translateKey1)}&nbsp;
            <a
                data-testid={testId}
                id={testId}
                className={clsx(styleGeneral.link, styleGeneral.link_underline, styleGeneral.link_fontWeightRegular)}
                href={SystemHelper.GetRuntimeConfig(urlConfigKey)}
                target={urlTargetName}
                onClick={() => {
                    sendAzureEvent(analyticsEventName);
                }}
            >
                {translate(translateKey2)}
            </a>
            {translate(translateKey3)}
        </span>
    );
    useEffect(() => {
        if (oktaData?.userName && app?.patient?.deviceClass) {
            sendAzureEvent(ConstantsHelper.analyticsEventNames.EULA_AND_CONF_AGR_LANDING);
        }
    }, [oktaData?.userName, app?.patient?.deviceClass]);

    return (
        <div className={clsx(styleGeneral.body, styleGeneral.whiteBackground)}>
            <div className={styleGuide.eulaAndConfAgr}>
                <div className={styleGuide.body}>
                    <div className={styleGuide.titleBlock}>
                        <div className={styleGuide.titleA}>
                            {translate('eulaAndConfAgr.titleA')}&nbsp;
                            <span className={styleGuide.titleBold}>{app?.patient?.physicianName ?? '--'}</span>
                            &nbsp;
                            {translate('eulaAndConfAgr.titleB')}&nbsp;
                            <span className={styleGuide.titleBold}>{app?.patient?.clinicName ?? '--'}</span>
                        </div>
                        <div className={styleGuide.titleB}>{pwdName}</div>
                        <div className={styleGuide.titleA}>
                            {translate('eulaAndConfAgr.dob', {
                                dateOfBirth: DateTimeHelper.FormatTimeStamp(
                                    app?.patient?.dateOfBirth,
                                    false,
                                    true,
                                    true,
                                    undefined,
                                    {
                                        primary: 'M/D/YYYY',
                                    } as IDateTimeFormats
                                ),
                                deviceClass: UtilityHelper.GetDeviceClassName(translate, app?.patient?.deviceClass),
                            })}
                        </div>
                    </div>
                    <div className={styleGuide.contact}>{translate('eulaAndConfAgr.contact')}</div>
                    <div className={styleGuide.need}>{translate('eulaAndConfAgr.need')}</div>
                    <div className={styleGuide.needBeforeAfter}>{translate('eulaAndConfAgr.needBefore')}</div>
                    <div className={styleGuide.checkBoxContainer}>
                        <CheckSingle
                            data-testid={'eulaAndConfAgr.chk1'}
                            id="eulaAndConfAgr.chk1"
                            checked={check1}
                            cbClickFn={(e: EventTarget & HTMLInputElement) => {
                                setCheck(
                                    setCheck1,
                                    e?.checked,
                                    ConstantsHelper.analyticsEventNames.EULA_AND_CONF_AGR_EULA_CHANGED,
                                    {
                                        accepted: !!e?.checked,
                                    }
                                );
                            }}
                            captionCb={() =>
                                handleEulaAndConfAgrCaptionCb(
                                    'eulaAndConfAgr.chkEulaA',
                                    'eulaAndConfAgr.chkEulaB',
                                    'eulaAndConfAgr.chkEulaC',
                                    'REACT_APP_ABOUT_URL_LEGAL',
                                    '__EULA__',
                                    'eulaAndConfAgr.link1',
                                    ConstantsHelper.analyticsEventNames.EULA_AND_CONF_AGR_EULA_LINK
                                )
                            }
                        />
                    </div>
                    <div className={styleGuide.checkBoxContainer}>
                        <CheckSingle
                            data-testid={'eulaAndConfAgr.chk2'}
                            id="eulaAndConfAgr.chk2"
                            checked={check2}
                            cbClickFn={(e: EventTarget & HTMLInputElement) => {
                                setCheck(
                                    setCheck2,
                                    e?.checked,
                                    ConstantsHelper.analyticsEventNames.EULA_AND_CONF_AGR_CONF_AGR_CHANGED,
                                    {
                                        accepted: !!e?.checked,
                                    }
                                );
                            }}
                            captionCb={() =>
                                handleEulaAndConfAgrCaptionCb(
                                    'eulaAndConfAgr.chkConfAgrA',
                                    'eulaAndConfAgr.chkConfAgrB',
                                    'eulaAndConfAgr.chkConfAgrC',
                                    'REACT_APP_ABOUT_URL_CONFIDENTIALITY',
                                    '__CONFIDENTIALITY__',
                                    'eulaAndConfAgr.link2',
                                    ConstantsHelper.analyticsEventNames.EULA_AND_CONF_AGR_CONF_AGR_LINK
                                )
                            }
                        />
                    </div>
                    <div className={styleGuide.needBeforeAfter}>{translate('eulaAndConfAgr.needAfter')}</div>
                    <div className={styleGuide.parLast} id={idBtnAgree}>
                        <BtnPrimary
                            disabled={!(check1 && check2)}
                            caption={translate('eulaAndConfAgr.agree')}
                            cbClickFn={() => cbAccept()}
                            focus={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContentWrapperEulaAndConfAgr;
