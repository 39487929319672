import React, { ReactNode, useEffect } from 'react';
import clsx from 'clsx';
import { Redirect } from 'react-router';
import { useOktaAuth } from '@okta/okta-react';

import { Patient } from 'src/model/patient';
import ConstantsHelper from 'src/helpers/ConstantsHelper';

import ContentWrapperEnrollmentPending from './ContentWrapperEnrollmentPending';
import ContentWrapperEnrollmentRevoked from './ContentWrapperEnrollmentRevoked';
import ContentWrapperSmsNumber from './ContentWrapperSmsNumber';
import ContentWrapperNotFound from './ContentWrapperNotFound';
import ContentWrapperDisclaimer from './ContentWrapperDisclaimer';
import ContentWrapperWelcome from './ContentWrapperWelcome';
import ContentWrapperEulaAndConfAgr from './ContentWrapperEulaAndConfAgr';

import { IObjectHash } from '../../../types';
import { pollProfileActionCreator } from '../../../store/app/app.slice';
import { setIsMenuVisible } from '../../../store/ui/ui.slice';
import { IAppState, IError, IPatient } from '../../../store/app/app.types';
import UiHelper from '../../../helpers/UiHelper';
import styleGeneral from '../../../styles/general.module.scss';
import SystemHelper from '../../../helpers/SystemHelper';
import InvitedToClinicModal from '../../../components/invitedToClinicModal/InvitedToClinicModal';
import { saveAndConfirmSmsNumber, updateProfile } from '../../../store/app/app.thunks';

const pickComponent = ({
    showError,
    showRevoked,
    showPending,
    showWelcome,
    showDisclaimer,
    showEulaAndConfAgr,
    showVerifySmsNumber,
    app,
    clearErrorCb,
    podderCentralCb,
    welcomeSeenCb,
    disclaimerSeenCb,
    eulaAndConfAgrCb,
    verifySmsNumberCb,
    confirmedSmsNumberCb,
    children,
}: {
    showError: boolean;
    showRevoked: boolean;
    showPending: boolean;
    showWelcome: boolean;
    showDisclaimer: boolean;
    showEulaAndConfAgr: boolean;
    showVerifySmsNumber: boolean;
    app: IAppState;
    clearErrorCb: any;
    podderCentralCb: any;
    welcomeSeenCb: any;
    disclaimerSeenCb: any;
    eulaAndConfAgrCb: any;
    verifySmsNumberCb: any;
    confirmedSmsNumberCb: any;
    children: any;
}) => {
    if (app?.reloadInProgress) {
        return null;
    } else if (showError) {
        return <ContentWrapperNotFound app={app} clearErrorCb={clearErrorCb} />;
    } else if (showEulaAndConfAgr) {
        return <ContentWrapperEulaAndConfAgr app={app} closeCb={eulaAndConfAgrCb} />;
    } else if (showVerifySmsNumber) {
        return <ContentWrapperSmsNumber app={app} verifyCb={verifySmsNumberCb} confirmedCb={confirmedSmsNumberCb} />;
    } else if (showRevoked) {
        return <ContentWrapperEnrollmentRevoked podderCentralCb={podderCentralCb} />;
    } else if (showPending) {
        return <ContentWrapperEnrollmentPending podderCentralCb={podderCentralCb} />;
    } else if (showWelcome) {
        return <ContentWrapperWelcome app={app} closeCb={welcomeSeenCb} />;
    } else {
        return (
            <>
                {children}
                {showDisclaimer && <ContentWrapperDisclaimer app={app} closeCb={disclaimerSeenCb} />}
                <InvitedToClinicModal app={app} />
            </>
        );
    }
};

function ContentWrapperAll({
    app,
    dispatch,
    showIfInvalidEnrollment,
    children,
}: Readonly<{
    app: IAppState;
    dispatch: any;
    showIfInvalidEnrollment: boolean;
    children?: ReactNode;
}>) {
    const patient: IPatient = app.patient ?? ({} as IPatient);
    const error: IObjectHash<IError> = app.errors ?? ({} as IObjectHash<IError>);
    const podderCentralCb = () =>
        window.location.assign(SystemHelper?.GetRuntimeConfig('REACT_APP_PODDER_CENTRAL_URL_LINK'));
    const clearErrorCb = () => SystemHelper.DismissError(dispatch);
    const disclaimerSeenCb = () => dispatch(updateProfile({ disclaimerSeen: true }));
    const eulaAndConfAgrCb = () =>
        dispatch(updateProfile({ eulaAccepted: true, confidentialityAgreementAccepted: true }));
    const welcomeSeenCb = () => {
        dispatch(updateProfile({ welcomeSeen: true }));

        return <Redirect to="/" />;
    };
    const verifySmsNumberCb = (smsNumber: string) => {
        dispatch(saveAndConfirmSmsNumber({ smsNumber }));
        dispatch(pollProfileActionCreator({ active: true }));
        return <Redirect to="/" />;
    };

    const confirmedSmsNumberCb = () => {
        dispatch(pollProfileActionCreator({ active: false }));
        return <Redirect to="/" />;
    };

    const redirectToHcp = () => {
        const hcpAppUrl = SystemHelper?.getConfig(
            ConstantsHelper.urlParamsConstants.hcpAppUrl.urlKey,
            ConstantsHelper.urlParamsConstants.hcpAppUrl.settingsKey,
            ConstantsHelper.urlParamsConstants.hcpAppUrl.runtimeKey,
            false,
            true
        );

        window.location.replace(hcpAppUrl);
    };

    const oktaAuthState = useOktaAuth()?.authState;
    const isPauseItEnabled = SystemHelper.IsPauseItEnabled();
    const oktaAccessToken = oktaAuthState.accessToken;

    if (oktaAccessToken && SystemHelper.IsHcpUserSession(oktaAccessToken, isPauseItEnabled)) {
        redirectToHcp();
    }

    useEffect(() => {
        if (!patient.isEnrolled) {
            const oktaData = SystemHelper.ParseOktaData(oktaAuthState, isPauseItEnabled);

            UiHelper.FetchPatient(dispatch, UiHelper.SaveOktaData(dispatch, oktaData));
            // UiHelper.FetchClinics(dispatch, UiHelper.SaveOktaData(dispatch, oktaData));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patient.isEnrolled]);

    const showError = UiHelper.HaveError(app) && !error.silent;
    const showRevoked = patient.isUnenrolled && !showIfInvalidEnrollment;
    const showPending = !patient.isUnenrolled && !patient.isEnrolled && !showIfInvalidEnrollment;
    const showWelcomeDisclaimer = !patient.isUnenrolled && patient.isEnrolled && !showIfInvalidEnrollment;
    const showWelcome = !patient.welcomeSeen && showWelcomeDisclaimer;
    const showDisclaimer = !patient.disclaimerSeen && patient.welcomeSeen && showWelcomeDisclaimer;
    const showEulaAndConfAgr = isPauseItEnabled && !(patient.eulaAccepted && patient.confidentialityAgreementAccepted);
    /**
     * TODO: showVerifySmsNumber should not have to care about app.isPolling, but needs to in order for the
     * confirmedSmsNumberCb function to be called upon rerender of the ContentWrapperConfirmSmsNumber. Should refactor
     * how together the App's setInterval and ContentWrapperSmsNumber are working together.
     */
    const showVerifySmsNumber =
        isPauseItEnabled &&
        (app.isPolling || !patient.smsNumberStatus) &&
        patient.eulaAccepted &&
        patient.confidentialityAgreementAccepted &&
        patient.deviceClass === Patient.DeviceClassEnum.Omnipod5;

    const newIsMenuVisible =
        !showError && !showVerifySmsNumber && !showEulaAndConfAgr && !showWelcome && !showDisclaimer;
    useEffect(() => {
        dispatch(setIsMenuVisible({ isMenuVisible: newIsMenuVisible }));
    }, [dispatch, newIsMenuVisible]);

    return (
        <div id="content-wrapper-all" className={styleGeneral.contentWrapperAll}>
            {UiHelper.IsSiteReadyToGo(app) ? (
                pickComponent({
                    showError,
                    showRevoked,
                    showPending,
                    showWelcome,
                    showDisclaimer,
                    showEulaAndConfAgr,
                    showVerifySmsNumber,
                    app,
                    clearErrorCb,
                    podderCentralCb,
                    welcomeSeenCb,
                    disclaimerSeenCb,
                    eulaAndConfAgrCb,
                    verifySmsNumberCb,
                    confirmedSmsNumberCb,
                    children,
                })
            ) : (
                <div className={styleGeneral.main} data-testid="content_wrapper_all">
                    <div className={clsx(styleGeneral.body, styleGeneral.whiteBackground)} />
                </div>
            )}
        </div>
    );
}

export default ContentWrapperAll;
