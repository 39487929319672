import { useTranslation } from 'react-i18next';
import React from 'react';
import clsx from 'clsx';

import styleGeneral from '../../../styles/general.module.scss';
import styleGuide from '../../../styles/styleGuide.module.scss';
import { ICallback } from '../../../types';
import BtnPrimary from '../btnPrimary';

function ContentWrapperEnrollmentRevoked({ podderCentralCb }: { podderCentralCb: ICallback<void> }) {
    const { t } = useTranslation();

    return (
        <div className={styleGuide.body}>
            <div
                className={clsx(
                    styleGeneral.errorPage,
                    styleGeneral.errorPageTest,
                    styleGeneral.layerTopmostLess1,
                    styleGuide.enrollmentRevoked
                )}
            >
                <div className={styleGuide.title}>{t('enrollmentRevoked.title')}</div>
                <div className={styleGuide.body}>{t('enrollmentRevoked.subTitle')}</div>
                <div>
                    <BtnPrimary caption={t('enrollmentRevoked.enrollNow')} cbClickFn={podderCentralCb} />
                </div>
            </div>
        </div>
    );
}

export default ContentWrapperEnrollmentRevoked;
